import { useAuthStore } from '@/stores';

export const getToken = () => {
  const { token } = useAuthStore();
  return token;
};

export const getLoginUrl = () => {
  return 'http://login.test.com';
};

export const delay = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timeout);
  });
};

export const downloadMaterials = () => {
  // disabled due to customer's request
  // const link = document.createElement('a');
  // const url = '6)Screen Design for GomiKan and GomiKanManegement.pdf';
  // link.href = url;
  // link.download = url.split('/').pop() || '';
  // link.click();
};
